<template>
	<!-- <svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
	>
		<path
			d="M3.33301 4.5C3.33301 3.39543 4.22844 2.5 5.33301 2.5H14.6663C15.7709 2.5 16.6663 3.39543 16.6663 4.5V17.5L14.583 15.8333L12.4997 17.5L9.99967 15.8333L7.49967 17.5L5.41634 15.8333L3.33301 17.5V4.5Z"
			stroke="currentColor"
			stroke-miterlimit="10"
			stroke-linecap="square"
			stroke-linejoin="round"
		/>
		<path
			d="M6.66602 5.83398H13.3327"
			stroke="currentColor"
			stroke-miterlimit="10"
			stroke-linecap="round"
		/>
		<path
			d="M6.66602 8.33398H13.3327"
			stroke="currentColor"
			stroke-miterlimit="10"
			stroke-linecap="round"
		/>
	</svg> -->

	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="20" height="20" viewBox="0 0 20 20" enable-background="new 0 0 2000 2000" xml:space="preserve">
<g>
	<path fill="#00AEEF" d="M522.96,549.035c4.519-3.493,9.232-6.807,13.96-10.16c2.361-1.679,4.73-3.364,7.107-5.056l3.57-2.54
		l3.649-2.457c4.878-3.266,9.789-6.554,14.732-9.864c4.954-3.285,10.079-6.385,15.152-9.614c2.545-1.6,5.098-3.205,7.658-4.814
		c2.606-1.536,5.22-3.076,7.841-4.62c2.619-1.544,5.245-3.092,7.878-4.644c2.637-1.544,5.249-3.142,7.962-4.589
		c5.378-2.962,10.785-5.94,16.22-8.933c2.693-1.533,5.491-2.896,8.256-4.329c2.771-1.42,5.548-2.844,8.333-4.271
		c2.784-1.422,5.574-2.847,8.371-4.276c2.837-1.346,5.68-2.696,8.53-4.048c2.847-1.352,5.7-2.707,8.559-4.065l4.29-2.038
		c1.437-0.668,2.903-1.28,4.355-1.923c5.815-2.544,11.654-5.099,17.516-7.663c5.901-2.462,11.885-4.782,17.847-7.192
		c5.936-2.475,12.031-4.606,18.107-6.818c6.083-2.196,12.142-4.499,18.354-6.423c24.596-8.4,49.971-15.196,75.683-20.799
		c12.83-2.952,25.86-5.057,38.859-7.331c6.533-0.894,13.076-1.789,19.627-2.685l4.908-0.66l4.932-0.479l9.862-0.962l9.859-0.961
		l9.892-0.567l9.885-0.574l4.94-0.29l4.949-0.083c6.589-0.115,13.18-0.23,19.774-0.345l19.744,0.467l4.928,0.115
		c1.642,0.04,3.281,0.202,4.92,0.297l9.828,0.657l9.812,0.646c3.267,0.236,6.518,0.688,9.773,1.019l9.751,1.071
		c3.243,0.395,6.501,0.608,9.72,1.182c6.443,0.995,12.882,1.989,19.314,2.982c3.221,0.425,6.397,1.149,9.583,1.772l9.54,1.91
		l9.514,1.904l9.411,2.334c3.131,0.773,6.259,1.545,9.385,2.317c3.121,0.784,6.258,1.471,9.327,2.453
		c6.158,1.82,12.306,3.637,18.441,5.45c3.08,0.841,6.094,1.918,9.108,2.97c3.014,1.043,6.026,2.084,9.034,3.125
		c3.005,1.035,6.006,2.07,9.004,3.103c2.998,1.025,5.912,2.311,8.865,3.447c5.879,2.332,11.742,4.658,17.59,6.977
		c5.739,2.587,11.463,5.167,17.169,7.739c2.845,1.295,5.705,2.534,8.523,3.861c2.784,1.412,5.563,2.823,8.338,4.23
		c5.539,2.81,11.06,5.611,16.561,8.401c5.38,3.036,10.741,6.062,16.081,9.075c2.667,1.5,5.329,2.997,7.986,4.491
		c2.595,1.617,5.185,3.231,7.769,4.841c41.476,25.465,78.85,54.514,111.912,84.849c33.09,30.333,61.887,61.915,86.946,92.399
		c3.141,3.8,6.25,7.56,9.324,11.279c3.056,3.736,5.895,7.62,8.802,11.351c5.741,7.537,11.451,14.785,16.831,22.025
		c5.255,7.353,10.375,14.517,15.353,21.481c2.466,3.498,4.99,6.856,7.307,10.321c2.309,3.469,4.583,6.886,6.821,10.248
		c4.476,6.714,8.808,13.213,12.989,19.486c2.137,3.092,3.999,6.334,5.95,9.405c1.922,3.095,3.807,6.131,5.655,9.106
		c3.695,5.943,7.239,11.643,10.626,17.09c1.747,2.719,3.203,5.386,4.723,7.981c1.5,2.595,2.963,5.125,4.388,7.59
		c2.85,4.92,5.548,9.577,8.087,13.96c1.27,2.188,2.5,4.307,3.689,6.356c0.595,1.024,1.18,2.03,1.754,3.019
		c0.575,0.996,1.159,2.115,1.722,3.136c4.312,7.936,8.002,14.726,11.04,20.316V629.483c-1.072-1.272-2.122-2.516-3.204-3.798
		c-6.597-7.545-13.588-15.126-20.619-22.954c-3.558-3.885-7.037-7.896-10.773-11.794c-3.758-3.879-7.557-7.8-11.397-11.762
		c-30.622-31.708-65.709-64.521-105.857-95.823c-40.106-31.294-85.318-61.024-135.081-86.329c-3.099-1.595-6.203-3.193-9.314-4.794
		c-3.183-1.476-6.371-2.956-9.567-4.438c-6.385-2.951-12.794-5.913-19.226-8.885c-6.542-2.752-13.15-5.418-19.75-8.13
		c-3.302-1.348-6.609-2.699-9.922-4.051c-3.351-1.266-6.749-2.441-10.128-3.665c-6.764-2.413-13.549-4.832-20.353-7.259
		c-6.908-2.126-13.834-4.258-20.779-6.396c-3.481-1.03-6.915-2.202-10.443-3.112c-3.524-0.913-7.052-1.827-10.584-2.743
		c-3.529-0.91-7.062-1.822-10.599-2.734c-3.533-0.916-7.062-1.853-10.662-2.551c-7.158-1.506-14.33-3.016-21.515-4.528
		c-3.573-0.818-7.217-1.337-10.84-1.949c-3.625-0.594-7.252-1.189-10.883-1.784c-3.627-0.591-7.256-1.182-10.888-1.773
		c-3.655-0.438-7.312-0.877-10.972-1.316l-10.975-1.295c-3.661-0.414-7.308-0.923-10.995-1.13
		c-7.348-0.539-14.702-1.079-22.062-1.62c-3.67-0.339-7.368-0.312-11.051-0.464l-11.055-0.328
		c-3.685-0.079-7.364-0.277-11.049-0.253l-11.052,0.141l-11.042,0.152c-1.839,0.042-3.678,0.017-5.516,0.115l-5.51,0.302
		l-22.003,1.247c-7.298,0.709-14.594,1.418-21.887,2.126l-5.46,0.538l-5.433,0.748l-10.852,1.5l-10.83,1.505l-10.749,1.909
		l-10.724,1.921l-5.352,0.962l-5.312,1.144c-7.065,1.55-14.122,3.097-21.168,4.643c-13.964,3.585-27.892,7.021-41.504,11.303
		c-27.292,8.268-53.895,17.747-79.388,28.776c-6.444,2.584-12.703,5.539-18.974,8.387c-6.265,2.864-12.535,5.645-18.619,8.761
		c-6.112,3.053-12.235,6.014-18.255,9.11c-5.964,3.196-11.906,6.38-17.822,9.55c-1.474,0.8-2.961,1.568-4.418,2.391l-4.345,2.503
		c-2.891,1.666-5.776,3.329-8.654,4.988c-2.875,1.658-5.744,3.313-8.606,4.964c-2.814,1.731-5.621,3.458-8.422,5.18
		c-2.795,1.726-5.584,3.449-8.366,5.167c-2.773,1.73-5.577,3.39-8.27,5.216c-5.425,3.577-10.822,7.136-16.19,10.676
		c-2.703,1.735-5.3,3.617-7.92,5.445c-2.614,1.835-5.22,3.665-7.819,5.49c-2.596,1.824-5.185,3.643-7.766,5.456
		c-2.53,1.885-5.052,3.765-7.566,5.638c-5.006,3.775-10.056,7.414-14.925,11.231c-4.848,3.837-9.664,7.649-14.448,11.436
		l-3.57,2.843l-3.488,2.923c-2.319,1.945-4.631,3.882-6.934,5.813c-4.608,3.855-9.193,7.662-13.578,11.641
		c-4.422,3.925-8.811,7.82-13.164,11.685c-4.244,3.982-8.454,7.931-12.628,11.848c-2.075,1.969-4.183,3.88-6.202,5.873
		c-2.013,1.998-4.017,3.987-6.012,5.967c-3.969,3.98-7.981,7.835-11.804,11.81c-7.592,8.009-15.153,15.729-22.144,23.695
		c-14.287,15.627-27.086,31.384-38.979,46.522c-2.898,3.846-5.758,7.643-8.581,11.388c-1.403,1.877-2.825,3.717-4.183,5.588
		c-1.335,1.888-2.661,3.763-3.978,5.624c-2.629,3.719-5.221,7.385-7.774,10.997c-2.494,3.653-4.861,7.315-7.236,10.88
		c-18.883,28.617-33.132,54.91-43.421,77.165c-10.293,22.27-16.737,40.412-20.781,52.847c-0.261,0.774-0.515,1.526-0.761,2.255
		c-0.213,0.74-0.419,1.458-0.618,2.151c-0.398,1.387-0.77,2.681-1.114,3.879c-0.687,2.395-1.266,4.41-1.732,6.033
		c-0.93,3.241-1.411,4.914-1.411,4.914s0.612-1.631,1.798-4.791c0.592-1.578,1.328-3.538,2.202-5.867
		c0.437-1.163,0.908-2.418,1.413-3.764c0.253-0.672,0.514-1.367,0.783-2.085c0.303-0.705,0.615-1.433,0.936-2.182
		c5.001-12.033,12.82-29.486,24.706-50.61c11.881-21.113,27.94-45.814,48.635-72.324c2.602-3.303,5.194-6.691,7.914-10.065
		c2.776-3.329,5.594-6.709,8.453-10.137c1.428-1.712,2.865-3.437,4.313-5.174c1.47-1.719,3.005-3.407,4.52-5.129
		c3.041-3.431,6.123-6.908,9.245-10.431c12.775-13.834,26.424-28.15,41.508-42.215c7.387-7.181,15.334-14.091,23.288-21.252
		c4.005-3.552,8.195-6.977,12.336-10.518c2.079-1.76,4.168-3.527,6.266-5.302c2.102-1.769,4.293-3.455,6.448-5.197
		c4.328-3.458,8.693-6.945,13.092-10.46C513.864,555.896,518.394,552.479,522.96,549.035z"/>
	<path fill="#00AEEF" d="M1685.949,1288.013c-29.558,14.697-59.591,28.375-89.904,41.438
		c-93.401,40.251-188.634,86.593-288.92,112.618c-7.599,1.972-15.227,3.827-22.886,5.555
		c-73.908,16.668-166.733,32.363-235.287-9.064c-111.744-67.528-91.425-227.634-49.482-330.616
		c50.726-124.546,139.098-225.9,222.716-329.192c-71.564,63.141-140.397,134.479-195.971,212.207
		c-56.833,79.489-101.916,173.016-118.569,269.813c-9.445,54.899-11.242,115.417-3.961,170.75
		c-67.86-50.857-85.713-138.73-81.522-219.356c2.282-43.9,10.768-88.667,22.99-132.073c12.235-43.457,28.154-85.672,45.916-125.706
		c8.876-20.028,18.24-39.516,27.889-58.398c9.627-18.895,19.571-37.167,29.623-54.782c20.102-35.235,40.66-67.816,60.534-97.358
		c19.872-29.55,39.047-56.077,56.522-79.265c17.493-23.176,33.247-43.051,46.444-59.25c13.198-16.201,23.831-28.732,31.08-37.282
		c7.307-8.502,11.204-13.037,11.204-13.037s-4.372,4.082-12.57,11.736c-8.147,7.709-20.108,19.017-35.052,33.724
		c-14.944,14.707-32.865,32.82-52.953,54.111c-20.07,21.305-42.27,45.824-65.612,73.436
		c-119.794,141.704-244.975,326.236-267.037,515.34c-6.381,54.691-1.375,111.55,16.272,163.813
		c10.386,30.756,31.198,66.157,55.908,90.821c-6.104-6.093-29.897-7.918-38.583-10.97c-13.358-4.695-26.348-10.402-38.881-16.987
		c-107.744-56.609-129.372-172.633-113.581-284.433c6.125-43.365,18.546-86.947,32.76-128.305
		c35.379-102.94,87.019-199.646,145.793-291.004c27.383-42.564,55.095-81.787,81.692-117.298
		c26.619-35.5,52.064-67.35,75.204-95.127c23.134-27.785,43.882-51.573,61.222-70.949c17.329-19.389,31.26-34.356,40.743-44.581
		c9.554-10.159,14.65-15.578,14.65-15.578s-5.558,4.947-15.979,14.221c-10.357,9.347-25.579,23.036-44.609,40.857
		c-19.042,17.809-41.902,39.74-67.567,65.522c-25.671,25.775-54.07,55.475-84.088,88.867
		C729.209,757.804,625.47,896.689,561.298,1054.006c-71.231,174.621-101.221,411.908,74.819,535.174
		c37.827,26.487,86.578,53.793,131.353,66.095c98.961,27.19,203.817,30.945,305.215,16.929
		c101.64-14.049,205.636-42.732,291.766-100.152c0,0,187.152-127.604,353.036-299.868
		C1710.781,1279.148,1694.386,1283.818,1685.949,1288.013z"/>
</g>
</svg>
</template>
